import { useRef, useEffect, useState } from 'react';
import type { ReactNode } from 'react';
import styled from 'styled-components';
import { space, ISpace } from 'helpers/genericStyles';

import { SubText } from 'components/Toolkit/Inputs/SubText';
import { ErrorMessage } from 'components/Toolkit/Inputs/ErrorMessage';
import { CharacterCount } from 'components/Toolkit/Inputs/CharacterCount';
import { HelpText } from 'components/Toolkit/Inputs/HelpText';
import { Label } from 'components/Toolkit/Inputs/Label';

import {
  Type,
  InputMode,
  AutoComplete,
  HeightVariant,
} from 'components/Toolkit/Inputs/types';

export interface TextFieldProps extends ISpace {
  /** Allow styled-components to style from parent */
  className?: string;
  /** Hint text to display */
  placeholder?: string;
  /** Initial value for the input */
  value?: string;
  /** Additional hint text to display */
  helpText?: string | null;
  /** Label for the input */
  label?: string | ReactNode;
  /** Disable the input */
  disabled?: boolean;
  /** Disable editing of the input */
  readOnly?: boolean;
  /** Automatically focus the input */
  autoFocus?: boolean;
  /** Force the focus state on the input */
  focused?: boolean;
  /** Does the input have an error */
  hasError?: boolean;
  /** Error message to display beneath the input */
  errorMessage?: string;
  /** Determine type of input */
  type?: Type;
  /** Name of the input */
  name?: string;
  /** ID for the input */
  id?: string;
  /** Defines a specific role attribute for the input */
  role?: string;
  /** Limit increment value for numeric and date-time inputs */
  step?: number;
  /** Hints at the type of data that might be entered by the user */
  inputMode?: InputMode;
  /** Enable automatic completion by the browser */
  autoComplete?: AutoComplete;
  /** Mimics the behavior of the native HTML attribute, limiting the maximum value */
  max?: number | string;
  /** Maximum character length for an input */
  maxLength?: number;
  /** Mimics the behavior of the native HTML attribute, limiting the minimum value */
  min?: number | string;
  /** Minimum character length for an input */
  minLength?: number;
  /** Indicates whether or not the character count should be displayed */
  showCharacterCount?: boolean;
  /** Callback when value is changed */
  onChange?(value: string): void;
  /** Callback when input is focused */
  onFocus?(): void;
  /** Callback when focus is removed */
  onBlur?(): void;
  /** Callback when input is clicked */
  onClick?(): void;
  /** Callback on keypress */
  onKeyPress?(event: React.KeyboardEvent): void;
  /** If to be used in forms */
  willUseSubText?: boolean;
  /** Use react-final-form meta object outside of field */
  metaCallback?: () => void;
  /* Allow for positioned component */
  topRightNode?: ReactNode;
  /** Configure text input*/
  pattern?: string;
  heightVariant?: HeightVariant;
}

type InputsStyleProps = {
  hasError: boolean;
  willUseSubText?: boolean;
  heightVariant?: HeightVariant;
};
const Inputs = styled.input<InputsStyleProps>`
  ${({ theme }) => theme.fontSize.M16};
  border-radius: ${({ theme }) => theme.borderRadius.default};
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  border: 1px solid ${({ theme }) => theme.colors.GREY};
  ${({ theme, hasError }) =>
    hasError &&
    `border-color: ${theme.colors.RED_DARK}; color: ${theme.colors.RED_DARK};`}
  padding: ${({ theme }) => `0 ${theme.spacing.M16}`};
  background-color: ${({ theme }) => theme.colors.WHITE};
  width: 100%;
  height: ${({ heightVariant }) =>
    heightVariant === 'LARGE' ? '48px' : '40px'};
  // Line Height needs to match the height to correct vertical alignment issue
  line-height: ${({ heightVariant }) =>
    heightVariant === 'LARGE' ? '48px' : '40px'};
  ${({ theme, willUseSubText }) =>
    willUseSubText ? `margin-bottom: ${theme.spacing.S4}` : ''};

  &:focus:not(.focus-visible) {
    outline: none;
  }

  &.focus-visible {
    border-width: 1px;
    border-style: solid;
    outline: none;
    border-color: ${({ theme }) => theme.colors.BLUE};
  }

  &:disabled {
    background-color: ${({ theme }) => theme.colors.GREY_LIGHTER};
    cursor: not-allowed;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &::-webkit-input-placeholder {
    color: ${({ theme, hasError }) =>
      hasError ? theme.colors.RED_DARK : theme.colors.GREY};
  }

  &[type='number'] {
    -moz-appearance: textfield; /* Firefox */
  }
`;

const LabelAndNodeContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const TextFieldContainer = styled.div`
  ${space};
`;

function TextField({
  type = 'text',
  placeholder,
  value = '',
  helpText,
  label,
  disabled,
  readOnly,
  autoFocus,
  focused,
  hasError = false,
  errorMessage,
  name,
  id,
  role,
  step,
  inputMode,
  autoComplete,
  max,
  maxLength,
  min,
  minLength,
  onChange,
  onFocus,
  onBlur,
  onClick,
  onKeyPress,
  showCharacterCount,
  className,
  willUseSubText = true,
  metaCallback,
  topRightNode,
  pattern,
  heightVariant = 'DEFAULT',
  ...spaceProps
}: TextFieldProps) {
  const alwaysSetId = id ? id : name;
  const inputRef = useRef<HTMLInputElement>(null);
  const [characterCount, setCharacterCount] = useState<number>(
    value?.length || 0,
  );
  const isError = hasError || Boolean(errorMessage && errorMessage.length > 0);

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    const val = event.currentTarget.value;
    if (showCharacterCount) {
      setCharacterCount(val.length);
    }
    onChange && onChange(val);
  }

  useEffect(() => {
    const input = inputRef.current;
    if (!input || focused === undefined) return;
    focused ? input.focus() : input.blur();
  }, [focused]);

  useEffect(() => {
    metaCallback && metaCallback();
    // TODO: Clean up this effect's dependencies. We're disabling this lint error for now so we can
    // clean up the lint logs. Ideally we should rewrite this code to be less error prone and trust
    // the lint rule's judgement.
    // https://distilledsch.tpondemand.com/RestUI/Board.aspx#page=userstory/98606
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasError]);

  useEffect(() => {
    if (value.length === 0) {
      setCharacterCount(0);
    }
  }, [value]);

  return (
    <TextFieldContainer className={className} {...spaceProps}>
      <LabelAndNodeContainer>
        {label && <Label htmlFor={alwaysSetId}>{label}</Label>}
        {topRightNode}
      </LabelAndNodeContainer>
      <Inputs
        name={name}
        id={alwaysSetId}
        disabled={disabled}
        readOnly={readOnly}
        role={role}
        autoFocus={autoFocus}
        value={value}
        placeholder={placeholder}
        onFocus={onFocus}
        onBlur={onBlur}
        onClick={onClick}
        onKeyPress={onKeyPress}
        inputMode={inputMode}
        autoComplete={autoComplete}
        onChange={handleChange}
        min={min}
        max={max}
        step={step}
        minLength={minLength}
        maxLength={maxLength}
        type={type}
        hasError={isError}
        ref={inputRef}
        willUseSubText={willUseSubText}
        pattern={pattern}
        heightVariant={heightVariant}
      />
      {willUseSubText && (
        <SubText>
          {errorMessage ? (
            <ErrorMessage text={errorMessage} />
          ) : (
            helpText && <HelpText text={helpText} />
          )}
          {Boolean(
            showCharacterCount && maxLength && !errorMessage && !helpText,
          ) && <CharacterCount count={characterCount} maxLength={maxLength!} />}
        </SubText>
      )}
    </TextFieldContainer>
  );
}

export { TextField, Inputs };
